import React, { useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
//Material-UI
import {
  Box,
  Card,
  CardContent,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

//custom css
import styles from './styles.module.scss';

const contentFormat = (inputObj) => {
  const outputObj = {};
  Object.keys(inputObj).forEach((key) => {
    switch (key) {
      case 'news_aid':
        outputObj.newsAid = inputObj[key];
        break;
      case 'news_card_img_src':
        outputObj.newsCardImgSrc = inputObj[key];
        break;
      case 'news_subject':
        outputObj.newsSubject = inputObj[key];
        break;
      case 'news_event_date':
        outputObj.newsEventDate = inputObj[key];
        break;
      case 'link_type':
        outputObj.linkType = inputObj[key];
        break;
      case 'news_link':
        outputObj.newsLink = inputObj[key];
        break;
      case 'if_top':
        outputObj.ifTop = inputObj[key];
        break;
      case 'news_content':
        outputObj.newsContent = inputObj[key];
        break;
      case 'referens_data': {
        const list = [...inputObj[key]];
        outputObj.referensData = list.map((item) => {
          const listObj = {};
          Object.keys(item).forEach((lkey) => {
            switch (lkey) {
              case 'ref_desc':
                listObj.refDesc = item[lkey];
                break;
              case 'ref_link':
                listObj.refLink = item[lkey];
                break;
              default:
                listObj[lkey] = item[lkey];
            }
          });
          return listObj;
        });
        break;
      }
      default:
        outputObj[key] = inputObj[key];
    }
  });
  return outputObj;
};

const CalIcon = ({ dateString }) => {
  let mouth = '';
  let day = 'News';

  if (dateString !== '' && dateString.split('/').length > 1) {
    mouth = dateString.split('/')[0];
    day = dateString.split('/')[1];
  }

  return (
    <div className={styles.date_display}>
      <div className={styles.date_display_cal}>
        <div className={styles.date_display_month}>{mouth}</div>
        <div
          className={
            day === 'News'
              ? cx(styles.date_display_day, styles.day_text)
              : styles.date_display_day
          }
        >
          {day}
        </div>
      </div>
    </div>
  );
};

const NewsList = ({ newsContent }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const newsInfo = contentFormat(newsContent);

  const handleOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  let ImgSrc = newsInfo.newsCardImgSrc;
  // if (newsInfo.newsCardImgSrc.search('reference') === -1) {
  //   let fileName = newsInfo.newsCardImgSrc.split('/')[1];
  //   ImgSrc = require('../../images/' + fileName);
  // }

  const handleClick = () => {
    window.open(newsInfo.newsLink, '_blank');
  };

  return (
    <React.Fragment>
      <Card style={{ display: 'flex' }}>
        <Box px={2} display="flex" alignItems="center">
          <CalIcon dateString={newsInfo.newsEventDate} />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          width={1}
        >
          <CardContent>
            <Box className={styles.news_title_list}>{newsInfo.newsSubject}</Box>
          </CardContent>
          <Box style={{ textAlign: 'right' }} p={2}>
            <Button
              className={styles.btn_more}
              onClick={newsInfo.linkType === 1 ? handleOpen : handleClick}
            >
              詳情
            </Button>
          </Box>
        </Box>
      </Card>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openDialog}
        onClose={handleClose}
        scroll={'paper'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          className={cx(styles.dialog_title)}
        >
          <span>{newsInfo.newsSubject}</span>
        </DialogTitle>
        <DialogContent dividers className={styles.dialog_content}>
          <img
            src={ImgSrc}
            className={cx(styles.img)}
            alt={newsInfo.newsSubject}
          ></img>
          <div dangerouslySetInnerHTML={{ __html: newsInfo.newsContent }} />
          {newsInfo.referensData.length > 0 && (
            <Box className={styles.news_ref}>
              <Box>其他參考資料：</Box>
              {newsInfo.referensData.map((item, index) => (
                <a
                  className={styles.ref}
                  key={index}
                  href={item.refLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.refDesc}
                </a>
              ))}
            </Box>
          )}
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button variant="outlined" size="small" onClick={handleClose}>
            關閉
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

NewsList.propTypes = {
  newsContent: PropTypes.shape({
    news_aid: PropTypes.number,
    news_card_img_src: PropTypes.string,
    news_subject: PropTypes.string,
    news_event_date: PropTypes.string,
    link_type: PropTypes.number,
    news_link: PropTypes.string,
    if_top: PropTypes.number,
    news_content: PropTypes.string,
    referens_data: PropTypes.arrayOf(
      PropTypes.shape({
        ref_desc: PropTypes.string,
        ref_link: PropTypes.string,
      })
    ),
  }),
};

export default NewsList;
