import config from '../config';
import AuthService from '../services/auth';

const Auth = new AuthService();
let pathUrl =
  config.env === 'dev' ? '/app1' : 'https://app.torchchurch.com/InternalSys';

const homePathUrl = () => {
  const urlRewriteId = localStorage.getItem('urlRewriteId')
    ? `/${localStorage.getItem('urlRewriteId')}`
    : '/torchchurch';
  // const host = window.location.host;
  let url = '';
  if (Auth.isAuthenticated()) {
    url = pathUrl + '/dashboard' + urlRewriteId;
  } else {
    url = pathUrl + urlRewriteId;
  }
  // console.log(url)
  return url;
};

const PRMPathUrl = (page) => {
  let url =
    config.env === 'dev'
      ? 'https://app.torchchurch.com/app2'
      : 'https://app.torchchurch.com/PRM';
  if (page !== '') {
    url = url + '/' + page;
  }
  return url;
};

const defaultPathUrl = () => {
  let url = pathUrl;
  return url;
};

export default {
  homePathUrl,
  PRMPathUrl,
  defaultPathUrl,
};
