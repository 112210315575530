import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const app = document.getElementById('root');
app.style.width = '100%';
app.style.height = '100%';
// 檢查版本並根據需要重新載入頁面
fetch('/InternalSys/version.json')
  .then((response) => response.json())
  .then((version) => {
    if (localStorage.getItem('APP_VERSION') !== version.appVersion) {
      localStorage.setItem('APP_VERSION', version.appVersion);
      window.location.reload(); // 重新載入頁面
    } else {
      // 確保渲染 App 之前進行版本檢查
      const root = ReactDOM.createRoot(app);
      root.render(<App />);
    }
  })
  .catch((error) => {
    console.error('Failed to fetch version.json:', error);
    // 如果無法獲取版本資料，也照常渲染應用程式
    const root = ReactDOM.createRoot(app);
    root.render(<App />);
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
