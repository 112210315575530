import React, { useEffect, useRef, useState } from 'react';
import useSWR from 'swr';
import { useParams, useNavigate } from 'react-router-dom';
import cx from 'classnames';

//Material-UI
import { Container, Grid } from '@mui/material';
import { useWindowSize } from '../../utils/useWindowSize';
//custom css
import styles from './styles.module.scss';
//import component
import Header from '../../components/header';
import Footer from '../../components/footer';
import Topgroupnews from '../../layout/topGroupNews';
import GroupNews from '../../layout/groupNews';
import Article from '../../layout/article';
import MeetingReportPie from '../../layout/meetingReportPie';
import MeetingReportLine from '../../layout/meetingReportLine';
import AuditList from '../../layout/auditList';
import AuditListApproved from '../../layout/auditListApproved';
import BirthdayList from '../../layout/birthdayList';
import AccountManager from '../../layout/accountManager';
import MemberList from '../../layout/memberList';
import MemberTUBibleStatus from '../../layout/memberTUBibleStatus';

//Sweetalert2
import { SwalWithCustomButtons } from '../../vendors/customSwal';

import AuthService from '../../services/auth';
import apiCaller from '../../utils/apiCaller';
import config from '../../config';
import httpStatus from '../../services/httpStatus';
import LoadingSpinner from '../../components/LoadingSpinner';

const Auth = new AuthService();

const Dashboard = () => {
  const navigate = useNavigate();
  let { urlRewriteId = 'torchchurch' } = useParams();
  const groupNewsRef = useRef(null);
  const topGroupNewsRef = useRef(null);
  const articleRef = useRef(null);
  const meetingReportPieRef = useRef(null);
  const meetingReportLineRef = useRef(null);
  const auditListRef = useRef(null);
  const auditListApprovedRef = useRef(null);
  const birthdayListRef = useRef(null);
  const accountManagerRef = useRef(null);
  const memberListRef = useRef(null);
  const memberTUBibleStatusRef = useRef(null);
  // const [isLoading, setIsLoading] = useState(false);
  //手機,電腦版的header height不同
  const size = useWindowSize();
  const offset = size.width >= 1200 ? 80 : 50;
  // const [dashoardView, setDashoardView] = useState({
  //   hasPRM: false,
  //   groupLeader: false,
  // });

  const refList = [
    'groupNewsRef',
    'topGroupNewsRef',
    'articleRef',
    'auditListRef',
    'auditListApprovedRef',
    'birthdayListRef',
    'accountManagerRef',
    'memberListRef',
    'meetingReportPieRef',
  ];

  let curRef = null;
  if (localStorage.getItem('ref_com') !== null) {
    let ans = refList.some((item) => item === localStorage.getItem('ref_com'));
    if (ans) {
      curRef = eval(localStorage.getItem('ref_com'));
    }
  }
  const fetcher = ([url, data]) =>
    apiCaller
      .apiFetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
      })
      .then((response) => {
        const hasPRM = response.Result.page_website_group_aid.some(
          (item) => item === 10
        );
        const groupLeader = response.Result.if_show_prm_other === 1;
        return { hasPRM: hasPRM, groupLeader: groupLeader };
      })
      .catch((err) => {
        console.log(err);
        httpStatus.errorStatus(err.response);
        return err;
      });

  const { data: dashboardView, isLoading } = useSWR(
    [config.account_dashboard_view_url, {}],
    fetcher,
    {
      fallbackData: {
        hasPRM: false,
        groupLeader: false,
      },
    }
  );

  useEffect(() => {
    if (urlRewriteId === 'torchty' || urlRewriteId === 'torchchurch') {
      if (sessionStorage.getItem('welcome') === 'true') {
        const userName = Auth.getProfile().user_name;
        SwalWithCustomButtons.fire({
          title: '哈囉！' + userName + ' 歡迎您！',
          icon: 'success',
          showConfirmButton: false,
          timer: 1000,
        }).then(() => sessionStorage.removeItem('welcome'));
      }
    } else {
      //404
      navigate('/404');
    }
  }, []);

  useEffect(() => {
    if (curRef !== null) {
      scrollToRef(curRef);
    }
  }, [dashboardView]);

  const scrollToRef = (ref) => {
    // console.log(ref);
    if (ref === null || ref.current === null) {
      return false;
    }

    return window.scrollTo({
      top: ref.current.offsetTop - offset,
      // behavior: "smooth"
    });
  };

  return (
    <div className={styles.page}>
      {isLoading && <LoadingSpinner />}
      <Header />
      <div className={styles.wrapper}>
        <Container maxWidth="xl" className={styles.container}>
          <h1 className={cx(styles.d_title_font, styles.d_title)}>
            火把領袖同工資訊平台
          </h1>
          <Grid container justify="center" spacing={3}>
            {/* 公告通知 */}
            <Grid
              item
              xs={12}
              sm={6}
              lg={4}
              className={styles.card_setting}
              ref={groupNewsRef}
            >
              <GroupNews />
            </Grid>
            {/* 置頂通知 */}
            <Grid
              item
              xs={12}
              sm={6}
              lg={4}
              className={styles.card_setting}
              ref={topGroupNewsRef}
            >
              <Topgroupnews />
            </Grid>
            {/* 休息一下 */}
            <Grid
              item
              xs={12}
              sm={6}
              lg={4}
              className={styles.card_setting}
              ref={articleRef}
            >
              <Article />
            </Grid>

            {/* 牧養權限開始 */}
            {/* page_website_group_aid10是牧養系統 */}
            {dashboardView.hasPRM && (
              <React.Fragment>
                {/* 主日小組回報率 */}
                {dashboardView.groupLeader && (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    lg={4}
                    className={styles.card_setting}
                    ref={meetingReportPieRef}
                  >
                    <MeetingReportPie />
                  </Grid>
                )}
                {/* 穩定／回報人數 */}
                {dashboardView.groupLeader && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    lg={8}
                    className={cx(styles.card_setting, styles.height_l)}
                    ref={meetingReportLineRef}
                  >
                    <MeetingReportLine />
                  </Grid>
                )}
                {/* 審核通知 */}
                {dashboardView.groupLeader && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    lg={8}
                    className={styles.card_setting}
                    ref={auditListRef}
                  >
                    <AuditList />
                  </Grid>
                )}
                {/* 審核進度 */}
                {dashboardView.groupLeader && (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    lg={4}
                    className={styles.card_setting}
                    ref={auditListApprovedRef}
                  >
                    <AuditListApproved />
                  </Grid>
                )}
                {/* 生日通知 */}
                {dashboardView.groupLeader && (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    lg={4}
                    className={styles.card_setting}
                    ref={birthdayListRef}
                  >
                    <BirthdayList />
                  </Grid>
                )}
                {/* 帳號管理 */}
                {dashboardView.groupLeader && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    lg={8}
                    className={styles.card_setting}
                    ref={accountManagerRef}
                  >
                    <AccountManager />
                  </Grid>
                )}
                {/* 成員列表 */}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  className={cx(styles.card_setting, styles.height_m)}
                  ref={memberListRef}
                >
                  <MemberList />
                </Grid>
                {/* TU聖經學院狀態 */}
                {dashboardView.groupLeader && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    className={cx(styles.card_setting, styles.auto_height)}
                    ref={memberTUBibleStatusRef}
                  >
                    <MemberTUBibleStatus />
                  </Grid>
                )}
              </React.Fragment>
            )}
            {/* 牧養權限結束 */}
          </Grid>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default Dashboard;
